var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.rulesValid,"error-messages":_vm.nameErrorMessages,"label":_vm.$lang('commonConfig.name'),"readonly":_vm.readonly,"atid":"rule_name_field"},model:{value:(_vm.settings.name),callback:function ($$v) {_vm.$set(_vm.settings, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"settings.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"6","atid":"active_since_view"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$lang('commonConfig.activeSince'),"rules":_vm.rulesActiveSince,"persistent-hint":"","readonly":"","prepend-icon":"event","atid":"active_since_field"},model:{value:(_vm.settings.activeSince),callback:function ($$v) {_vm.$set(_vm.settings, "activeSince", $$v)},expression:"settings.activeSince"}},Object.assign({}, onMenu, onTooltip)))]}}],null,true)},[_c('pre',[_vm._v(_vm._s(_vm.$lang("commonConfig.inputActiveSinceHint")))])])]}}]),model:{value:(_vm.menuActiveSince),callback:function ($$v) {_vm.menuActiveSince=$$v},expression:"menuActiveSince"}},[_c('v-date-picker',{attrs:{"min":_vm.minActiveSince,"max":_vm.settings.activeTill,"first-day-of-week":1,"readonly":_vm.readonly,"no-title":"","atid":"date_picker_active_since"},on:{"input":function($event){_vm.menuActiveSince = false}},model:{value:(_vm.settings.activeSince),callback:function ($$v) {_vm.$set(_vm.settings, "activeSince", $$v)},expression:"settings.activeSince"}})],1)],1),_c('v-col',{attrs:{"sm":"12","md":"6","atid":"active_till_view"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$lang('commonConfig.activeTill'),"rules":_vm.rulesActiveTill,"persistent-hint":"","readonly":"","prepend-icon":"event","atid":"active_till_field"},model:{value:(_vm.settings.activeTill),callback:function ($$v) {_vm.$set(_vm.settings, "activeTill", $$v)},expression:"settings.activeTill"}},Object.assign({}, onMenu, onTooltip)))]}}],null,true)},[_c('pre',[_vm._v(_vm._s(_vm.$lang("commonConfig.inputActiveTillHint")))])])]}}]),model:{value:(_vm.menuActiveTill),callback:function ($$v) {_vm.menuActiveTill=$$v},expression:"menuActiveTill"}},[_c('v-date-picker',{attrs:{"min":_vm.minActiveTill,"first-day-of-week":1,"readonly":_vm.readonly,"no-title":"","atid":"date_picker_active_till"},on:{"input":function($event){_vm.menuActiveTill = false}},model:{value:(_vm.settings.activeTill),callback:function ($$v) {_vm.$set(_vm.settings, "activeTill", $$v)},expression:"settings.activeTill"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }