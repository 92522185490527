



































































































import { mixins } from "vue-class-component";
import { Component, Vue, Watch } from "vue-property-decorator";
import { NavigationGuardNext, Route } from "vue-router";

import Rules from "@/ab-tests/components/Rules.vue";
import TargetedConfigSettings from "@/ab-tests/components/TargetedConfigSettings.vue";
import { RuleOperator } from "@/ab-tests/models/RuleModel";
import TargetedConfigurationModel from "@/ab-tests/models/TargetedConfigurationModel";
import TargetedConfigStore from "@/ab-tests/store/TargetedConfigStore";
import ConfigUtil from "@/ab-tests/utils/ConfigUtil";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import { AppSection } from "@/shared/models";

@Component({
  components: {
    Rules,
    TargetedConfigSettings,
  },
})
export default class TargetedConfigurationEditView extends mixins(
  UnsavedChangesMixin
) {
  store: TargetedConfigStore = this.$store.state.targetedConfig;
  targetedConfig: TargetedConfigurationModel = TargetedConfigurationModel.of(
    this.store.targetedConfig
  );
  existingNames: Array<string> = [];
  confirmDeploy = false;
  isWatchedTargeted = false;
  fromRoute: Route | null = null;
  errorTooltipVisible = false;

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return Object.assign({}, this.targetedConfig);
  }

  get maxDateInRules() {
    return ConfigUtil.maxDateInRules(this.targetedConfig.rules);
  }

  get configId(): number {
    return Number.parseInt(this.$route.params.configId);
  }

  get hasOnlyExcludeRules(): boolean {
    return (
      !!this.targetedConfig.rules.length &&
      this.targetedConfig.rules.length ===
        this.targetedConfig.rules.filter(
          ({ operator }) => operator === RuleOperator.EXCLUDE
        ).length
    );
  }

  get errorMessages(): Array<string> {
    const messages: Array<string> = [];

    if (this.hasOnlyExcludeRules) {
      messages.push(this.$lang("targetedConfig.error.onlyExcludeRules"));
    }

    if (!this.targetedConfig.rules.length) {
      messages.push(this.$lang("targetedConfig.error.rulesRequired"));
    }

    if (
      this.maxDateInRules &&
      this.targetedConfig.activeTill &&
      this.maxDateInRules > this.targetedConfig.activeTill
    ) {
      messages.push(this.$lang("targetedConfig.error.maxDateInRules"));
    }

    if (
      this.targetedConfig.activeTill &&
      this.targetedConfig.activeSince &&
      this.targetedConfig.activeTill < this.targetedConfig.activeSince
    ) {
      messages.push(
        this.$lang("targetedConfig.error.activeSinceLaterThanActiveTill")
      );
    }

    if (
      this.targetedConfig.activeSince &&
      ConfigUtil.getCurrentDate() > this.targetedConfig.activeSince
    ) {
      messages.push(
        this.$lang("targetedConfig.error.currentDateLaterThanActiveSince")
      );
    }

    return messages;
  }

  get hasUnsavedChanges(): boolean {
    return this.$store.state.unsavedChangesStore.hasUnsavedChanges;
  }

  get appId(): string {
    return this.$route.params.id;
  }

  @Watch("configId", { immediate: true })
  private watchConfigId(configId: string) {
    this.$store.dispatch("getTargetedConfig", configId);
  }

  @Watch("store.targetedConfig", { deep: true })
  private watchTargetedConfig(targetedConfig: TargetedConfigurationModel) {
    this.targetedConfig = TargetedConfigurationModel.of(targetedConfig);
    this.initNames();

    if (!this.isWatchedTargeted) {
      this.isWatchedTargeted = true;
      this.handleWatchingUnsavedChanges();
    }
  }

  created() {
    this.initNames();
  }

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    next((vm: Vue & { fromRoute?: Route }) => {
      vm.fromRoute = from;
    });
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  goBack() {
    this.$router.push({
      name: this.fromRoute?.name ?? AppSection.TARGETED_CONFIGS,
    });
  }

  initNames() {
    this.existingNames = this.$store.getters["targetedConfigNames"].slice();

    if (!this.targetedConfig.name) {
      return;
    }

    const nameIndex = this.existingNames.indexOf(
      this.targetedConfig.name.toLowerCase()
    );

    if (nameIndex === -1) {
      return;
    }

    this.existingNames.splice(nameIndex, 1);
  }

  save() {
    if (this.errorMessages.length) {
      this.errorTooltipVisible = true;

      return;
    }

    this.$store.dispatch("updateTargetedConfig", this.targetedConfig);
    this.isSavedForm = true;
    this.$router.push({
      name: AppSection.TARGETED_CONFIGS,
    });
  }

  hideErrorTooltip() {
    this.errorTooltipVisible = false;
  }
}
