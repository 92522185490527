





































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { debounce } from "lodash";

import TargetedConfigurationModel from "@/ab-tests/models/TargetedConfigurationModel";
import ConfigUtil from "@/ab-tests/utils/ConfigUtil";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class TargetedConfigSettings extends Vue {
  @Prop() settings!: TargetedConfigurationModel;
  @Prop({ default: () => [] }) existingNames!: Array<string>;
  @Prop() minDate?: string;
  @Prop({ default: false }) isEditable?: boolean;
  @Prop({ default: false }) readonly!: boolean;

  menuActiveSince = false;
  menuActiveTill = false;

  get appId(): string {
    return this.$route.params.id;
  }

  get rulesValid() {
    return [
      ValidUtil.required(this.$lang("validation.required")),
      ValidUtil.maxLength(255, this.$lang("validation.maxLength", 255)),
      ValidUtil.unique(this.existingNames, this.$lang("validation.unique")),
    ];
  }

  get rulesActiveTill() {
    return [
      ValidUtil.minDate(
        this.settings.activeSince,
        this.$lang("validation.min", this.settings.activeSince || "")
      ),
      ValidUtil.minDate(
        ConfigUtil.getCurrentDate(),
        this.$lang("validation.min", ConfigUtil.getCurrentDate())
      ),
      ValidUtil.minDate(
        this.minDate,
        this.$lang("validation.min", this.minDate || "")
      ),
    ];
  }

  get rulesActiveSince() {
    return !this.isEditable
      ? [
          ValidUtil.maxDate(
            this.settings.activeTill,
            this.$lang("validation.min", this.settings.activeTill || "")
          ),
          ValidUtil.minDate(
            ConfigUtil.getCurrentDate(),
            this.$lang("validation.min", ConfigUtil.getCurrentDate())
          ),
        ]
      : [];
  }

  get minActiveSince() {
    return !!this.minDate && this.minDate > this.currentDate
      ? this.minDate
      : this.currentDate;
  }

  get minActiveTill() {
    let dates = [];
    if (this.minDate) {
      dates.push(this.minDate);
    }
    if (this.settings.activeSince) {
      dates.push(this.settings.activeSince);
    }
    dates = dates.sort().reverse();
    return dates.length > 0 && dates[0] > this.currentDate
      ? dates[0]
      : this.currentDate;
  }

  get currentDate() {
    return ConfigUtil.getCurrentDate();
  }

  get isTargetedConfigNameUnique(): boolean {
    return this.$store.state.targetedConfig.isTargetedConfigNameUnique;
  }

  get nameErrorMessages(): Array<string> {
    if (!this.isTargetedConfigNameUnique) {
      return [this.$lang("shared.errors.notUnique")];
    }
    if (this.settings.name && this.settings.name.length > 50) {
      return [this.$lang("shared.errors.maxCharacters", 50)];
    }

    return [];
  }

  @Watch("settings.name")
  watchName = debounce(() => {
    this.$store.dispatch("checkIsTargetedConfigNameUnique", {
      appId: this.appId,
      targetedConfig: this.settings,
    });
  }, 500);
}
